import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import { Modal } from "reactstrap";

import Autocomplete from "./Subcomponents/Autocomplete";

import caretRight from "../customs_forms/images/caret-right.svg";
import alert from "../customs_forms/images/alert.svg";
import TotalItemValuePopover from "./Popovers/TotalItemValuePopover";
import TotalItemWeightPopover from "./Popovers/TotalItemWeightPopover";
import LoggedInNav from "./LoggedInNav";
import isDomesticDestination from "../utils/isDomesticDestination";
import * as analytics from "../Components/Analytics/Analytics.js";
import LookupHsTariffCode from "./Modals/LookupHsTariffCode";
import LandedCostEstimate from "./Modals/LandedCostEstimate";
import ProhibitedRestrictedItem from "./Modals/ProhibitedRestrictedItem";
import validation from "../utils/validation";
import hsTariffCodeUtils from "../utils/hsTariffCodeUtils";

var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-7b-package-contents-add-items"
};
class ItemEntry extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.cartInfo = this.props.location.state.previousState.cartInfo;
    this.packageInfo = this.props.location.state.previousState.packageInfo;
    this.officialBusiness = this.previousState.officialBusiness;
    this.commercialSender =
      this.props.location.state.previousState.commercialSender;
    const itemEntry = this.props.location.state.previousState.itemEntry;
    if (itemEntry && itemEntry.itemsList && itemEntry.itemsList.length > 0) {
      itemEntry.itemsList[itemEntry.itemsList.length - 1].readOnly = false;
      this.state = {
        ...itemEntry
      };
    } else {
      this.state = {
        // Counter to ensure each item row has a unique identifier
        globalKey: 1,
        itemsList: [
          {
            key: 1,
            itemDesc: "",
            itemQty: "",
            unitValue: "",
            weightPounds: "",
            weightOunces: "",
            canOmitHsTariff: false,
            hsTariffNbr: "",
            unitWeightOz: "0",
            countryOfOrigin: "",
            readOnly: false,
            // Fields added for TLC (total landed cost)
            tlcCategory: "",
            tlcSubcategory: "",
            tlcItemDetail: "",
            tlcItemColor: "",
            tlcBrand: "",
            tlcUpcCode: "",
            tlcProductLength: "",
            tlcProductWidth: "",
            tlcProductHeight: ""
          }
        ],
        tlcAmounts: null
      };
    }
    this.state = {
      ...this.state,
      modalWeightChoice: "original",
      modalValueChoice: "original",
      tlcEmail: "",
      hsTariffMandatory: true
    };

    this.maxLengths = {
      itemDesc: "56",
      itemQty: "3",
      unitValue: "9",
      weightPounds: "5",
      weightOunces: "5",
      hsTariffNbr: "16",
      tlcItemDetail: "100",
      tlcItemColor: "100",
      tlcBrand: "100",
      tlcUpcCode: "100",
      tlcProductLength: "5",
      tlcProductWidth: "5",
      tlcProductHeight: "5"
    };
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no commercial sender info
    window.location.replace("/index");
  };

  // Return a new array containing only the country names
  formatCountryList = () => {
    let newCountryList = [];
    for (let i in this.props.countryList) {
      newCountryList.push(this.props.countryList[i].countryName);
    }
    return newCountryList;
  };

  componentDidMount() {
    this.props.loginCheck();
    // if(this.previousState.renderServiceOption && this.previousState.renderServiceOption === 1){
    //   this.previousState.exemptionStatus =0;
    // }
    if (!this.previousState.itemStatus ||
      (this.previousState.itemStatus && this.previousState.itemStatus === 0)) {
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
    this.props.toggleLoadingBar(true);
    Promise.all([
      axios.get(
        "/CFOApplication/fetchlabelInfo?cnsCartId=" +
        encodeURIComponent(this.cartInfo.encodedCartId)
      ),
      axios.get("/CFOApplication/fetchUserPreferences")
    ])
      .then((response) => {
        this.setState({
          shipFromCountryCode: response[0].data.returnAddress.countryCode,
          tlcEmail:
            response[0].data.returnAddress.emailAddress ||
            response[1]?.data?.preferencesData?.ptsTrackingEmail ||
            this.props.custRegEmail ||
            "",
          productClass: response[0].data.productId?.productClass
        });
        this.callImc(response[0].data.deliveryAddress);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  }

  callImc = (deliveryAddress) => {
    const countryCode = deliveryAddress.countryCode;
    const stateCode = deliveryAddress.stateCode;
    axios
      .post("/CFOApplication/tlc/imc/hsCode?countryAbbv=" + countryCode)
      .then((response) => {
        this.hsTariffCommercialChecks(response, countryCode, stateCode);
      })
      .catch((error) => {
        console.log(error);
        this.hsTariffCommercialChecks(null, countryCode, stateCode);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
  };

  hsTariffCommercialChecks = (response, countryCode, stateCode) => {
    // Display the HS Tariff and Country of Origin fields
    // if the delivery country requires an HS tariff,
    // or if the destination is the AE state,
    // or if the user is a commercial sender
    const hsTariffCodeRequired =
      (response.data && response.data.hsCodeRequired) ?? false;
    const hsTariffMandatory =
      (response.data && response.data.hsCodeMandatory) ?? true;
    let displayCommercial = false;
    if (hsTariffCodeRequired) {
      displayCommercial = true;
    } else if (stateCode === "AE") {
      displayCommercial = true;
    } else if (
      this.commercialSender &&
      this.commercialSender.isCommercialSender === "1"
    ) {
      displayCommercial = true;
    }
    if (displayCommercial) {
      this.formattedCountryList = this.formatCountryList();
    }
    // Update state
    this.setState({
      hasLoaded: true,
      hsTariffMandatory: hsTariffMandatory,
      isHsTariffRequired: hsTariffCodeRequired || stateCode === "AE",
      shipToCountryCode: countryCode,
      displayCommercial: displayCommercial
    });
  };

  // Show weight input fields if the item is being edited or if the weight was
  // already provided.  This is so that placeholders are properly hidden when
  // the fields are read-only and no weight was entered.
  displayWeight = (readOnly, weight) => {
    return !readOnly || Number(weight);
  };

  // Show the HS tariff input field if the item is being edited or if the
  // HS tariff was already provided.  This is so that the placeholder is
  // properly hidden when the field is read-only and no HS tariff was entered.
  displayHsTariff = (readOnly, hsTariffNbr) => {
    return !readOnly || hsTariffNbr;
  };

  // Show the country of origin input field if the item is being edited or if
  // the country of origin was already provided.  This is so that the
  // placeholder is properly hidden when the field is read-only and no
  // country of origin was entered.
  displayCountryOfOrigin = (readOnly, countryOfOrigin) => {
    return !readOnly || countryOfOrigin;
  };

  handleOfficialBusinessTariffCode = (event, index) =>{
    const value = event.target.options[event.target.selectedIndex].label;
    this.updateHsTariff(index, event.currentTarget.value)

    this.handleItemEntry(
      event.currentTarget.name,
      value,
      index
    );
  }

  handleItemEntryEvent = (event, index) => {
    // If the user modified a required input field other than the
    // HS tariff, reset the flag that determines if an HS tariff is required
    if (event.currentTarget.name !== "hsTariffNbr") {
      this.setCanOmitHsTariff(false, index);
    }
    // Update the input field that was modified
    this.handleItemEntry(
      event.currentTarget.name,
      event.currentTarget.value,
      index
    );
  };

  // Get the country of origin input from the autocomplete component
  getDataFromChild = (countryName, index) => {
    // Reset the flag that determines if an HS tariff is required
    this.setCanOmitHsTariff(false, index);
    // Update the country of origin
    this.handleItemEntry("countryOfOrigin", countryName, index);
  };

  handleItemEntry = (name, value, index) => {
    index--;
    // Update the items list by first performing a deep copy using an
    // updater function with setState()
    this.setState((state) => {
      // Make a shallow copy of the items list that is currently in
      // state
      let newItemsList = [...state.itemsList];
      // Check for valid inputs in the quantity, weight, value, and HS tariff
      // fields
      if (
        name === "itemQty" ||
        name === "weightPounds" ||
        name === "weightOunces"
      ) {
        value = value.replaceAll(/\D/g, "");
      } else if (name === "unitValue" && !validation.currency.test(value)) {
        value = newItemsList[index].unitValue;
      } else if (name === "hsTariffNbr") {
        if (value.replace(/[^0-9]/g, "").length <= 14) {
          value = value.replaceAll(/[^0-9.]/g, "");
        } else {
          value = newItemsList[index].hsTariffNbr;
        }
      }
      // Make a shallow copy of the specific item,
      // update that item, and then update the new items list
      newItemsList[index] = {
        ...newItemsList[index],
        [name]: value
      };
      // Update state using the new items list, and indicate which
      // row was last modified
      return {
        itemsList: newItemsList,
        currentIndex: index
      };
    });
  };

  isRowValueOrWeightValid = (index, exceedsMaximumValueOrWeight) => {
    if (this.state.itemsList.length !== index) {
      // For any row other than the last, the "Add" button only appears
      // if the entered value/weight does not exceed the maximum
      return !(exceedsMaximumValueOrWeight > 0);
    } else {
      // For the last row, the "Add" button only appears
      // if the entered value/weight does not exceed or equal the maximum
      return !(exceedsMaximumValueOrWeight >= 0);
    }
  };

  displayAddButton = (item, index) => {
    // If the user is not editing this item, do not display the continue button
    if (item.readOnly) {
      return false;
    }
    // Validate the HS tariff field
    if (
      this.state.isHsTariffRequired &&
      (!item.canOmitHsTariff || this.state.hsTariffMandatory) &&
      !item.hsTariffNbr
    ) {
      return false;
    } else if (item.hsTariffNbr === " " && Number(item.itemQty) > 0){
      return true;
    }
    else if (
      item.hsTariffNbr &&
      (item.hsTariffNbr.length < 6 || item.hsTariffNbr.length > 16)
    ) {
      return false;
    }
    // Validate the row depending on the flow type
    const flowTypeOrReceipt = this.getFlowTypeOrReceipt();
    switch (flowTypeOrReceipt) {
      // Domestic flows
      case "military":
      case "territories":
      case "usa":
      case "militaryToUSA":
        // If the description, quantity, and weight have all been
        // provided, and the maximum weight has not been
        // exceeded, then this row is validated
        return (
          item.itemDesc &&
          item.itemDesc.trim() &&
          Number(item.itemQty) > 0 &&
          (Number(item.weightPounds) > 0 || Number(item.weightOunces) > 0) &&
          this.isRowValueOrWeightValid(index, this.exceedsMaximumWeight)
        );
      // International flows
      case "Canada":
      case "other":
        // If the description, quantity, value, and weight have all been
        // provided, and the maximum value and maximum weight have not been
        // exceeded, then this row is validated
        return (
          item.itemDesc &&
          item.itemDesc.trim() &&
          Number(item.itemQty) > 0 &&
          Number(item.unitValue) > 0 &&
          (Number(item.weightPounds) > 0 || Number(item.weightOunces) > 0) &&
          this.isRowValueOrWeightValid(index, this.exceedsMaximumValue) &&
          this.isRowValueOrWeightValid(index, this.exceedsMaximumWeight)
        );
      // Receipt flows
      case "domesticReceipt":
        return (
          // If the description and quantity have been
          // provided, then this row is validated
          item.itemDesc && item.itemDesc.trim() && Number(item.itemQty) > 0
        );
      case "internationalReceipt":
        return (
          // If the description, quantity, and value have all been
          // provided, and the maximum value has not been
          // exceeded, then this row is validated
          item.itemDesc &&
          item.itemDesc.trim() &&
          Number(item.itemQty) > 0 &&
          Number(item.unitValue) > 0 &&
          this.isRowValueOrWeightValid(index, this.exceedsMaximumValue)
        );
      default:
        return false;
    }
  };

  // Verify whether the item to be added is prohibited or restricted
  checkForProhibitions = (index, proceedIfAllowed) => {
    const item = this.state.itemsList[index - 1];
    if (
      this.previousState.flowType !== "military" &&
      this.state.isHsTariffRequired &&
      item.hsTariffNbr
    ) {
      this.props.toggleLoadingBar(true);
      axios
        .post("/CFOApplication/tlc/restrictions", {
          items: [
            {
              country_of_origin: this.get2LetterCountryCode(
                item.countryOfOrigin
              ),
              description: item.itemDesc,
              hs_code: item.hsTariffNbr
            }
          ],
          ship_from_country: this.state.shipFromCountryCode,
          ship_to_country: this.state.shipToCountryCode,
          restriction_type_threshold: "OBSERVATION"
        })
        .then((response) => {
          const prohibitionsRestrictions =
            response?.data?.items?.[0]?.item_restrictions || [];
          this.setState({
            prohibitionsRestrictions: prohibitionsRestrictions
          });
          if (prohibitionsRestrictions.length > 0) {
            this.handleProhibitedItemModal(index, proceedIfAllowed);
            this.props.toggleLoadingBar(false);
          } else {
            if (proceedIfAllowed) {
              this.handleContinueButton();
            } else {
              this.handleAddButton(index);
              this.props.toggleLoadingBar(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (proceedIfAllowed) {
            this.handleContinueButton();
          } else {
            this.handleAddButton(index);
            this.props.toggleLoadingBar(false);
          }
        });
    } else {
      if (proceedIfAllowed) {
        this.handleContinueButton();
      } else {
        this.handleAddButton(index);
      }
    }
  };

  // Get the 2-letter country code for the provided country name
  get2LetterCountryCode = (countryName) => {
    let countryCode2Letter = null;
    for (let index in this.props.countryList) {
      if (countryName === this.props.countryList[index].countryName) {
        countryCode2Letter = this.props.countryList[index].alpha2Abbrv;
        break;
      }
    }
    return countryCode2Letter;
  };

  handleAddButton = (index) => {
    index--;
    this.setState((state) => {
      let newItemsList = [...state.itemsList];
      newItemsList[index] = {
        ...newItemsList[index],
        readOnly: true
      };
      if (newItemsList.length < 30 && newItemsList.length === index + 1) {
        newItemsList.push({
          key: state.globalKey + 1,
          itemDesc: "",
          itemQty: "",
          unitValue: "",
          weightPounds: "",
          weightOunces: "",
          unitWeightOz: "0",
          hsTariffNbr: "",
          countryOfOrigin: "",
          readOnly: false,
          // Fields added for TLC (total landed cost)
          tlcCategory: "",
          tlcSubcategory: "",
          tlcItemDetail: "",
          tlcItemColor: "",
          tlcBrand: "",
          tlcUpcCode: "",
          tlcProductLength: "",
          tlcProductWidth: "",
          tlcProductHeight: ""
        });
      }
      return {
        globalKey: state.globalKey + 1,
        itemsList: newItemsList
      };
    });
  };

  displayEditButton = (readOnly) => {
    return readOnly;
  };

  handleEditButton = (index) => {
    index--;
    this.setState((state) => {
      let newItemsList = [...state.itemsList];
      newItemsList[index] = {
        ...newItemsList[index],
        readOnly: false
      };
      return {
        itemsList: newItemsList
      };
    });
  };

  displayRemoveButton = () => {
    return this.state.itemsList.length > 1;
  };

  handleRemoveButton = (index) => {
    index--;
    this.setState((state) => {
      let newItemsList = [...state.itemsList];
      newItemsList.splice(index, 1);
      if (newItemsList.length === index) {
        index--;
        newItemsList[index] = {
          ...newItemsList[index],
          readOnly: false
        };
      } else if (newItemsList.length === 1) {
        newItemsList[index] = {
          ...newItemsList[index],
          readOnly: false
        };
      }
      return {
        itemsList: newItemsList
      };
    });
  };

  updateValueAndWeight = () => {
    // Update current total items value and total items weight
    this.totalItemsValue = 0;
    this.totalItemsWeightPounds = 0;
    this.totalItemsWeightOunces = 0;
    for (let i in this.state.itemsList) {
      this.totalItemsValue += Number(this.state.itemsList[i].unitValue);
      this.totalItemsWeightOunces +=
        Number(this.state.itemsList[i].weightPounds) * 16 +
        Number(this.state.itemsList[i].weightOunces);
    }
    this.totalItemsWeightPounds = Math.floor(this.totalItemsWeightOunces / 16);
    this.totalItemsWeightOunces = this.totalItemsWeightOunces % 16;
    // Check if the inputs equal or exceed the maximum allowed values
    this.exceedsMaximumValue =
      this.totalItemsValue - Number(this.packageInfo.totalPackageValue);
    this.exceedsMaximumWeight =
      this.totalItemsWeightPounds * 16 +
      this.totalItemsWeightOunces -
      (Number(this.packageInfo.weightPounds) * 16 +
        Number(this.packageInfo.weightOunces));
  };

  // Display the total items weight if either pounds or ounces has
  // been entered
  displayTotalItemsWeight = () => {
    return this.totalItemsWeightPounds > 0 || this.totalItemsWeightOunces > 0;
  };

  // Display a message indicating the inputted total items value
  // either equals or exceeds the total package value
  displayMaximumValue = () => {
    return (
      this.exceedsMaximumValue >= 0 &&
      !isDomesticDestination(this.previousState.flowType)
    );
  };

  // Display a message indicating the inputted total items weight
  // either equals or exceeds the total package weight
  displayMaximumWeight = () => {
    return (
      this.exceedsMaximumWeight >= 0 &&
      this.previousState.receiptCheck2 !== "domesticReceipt" &&
      this.previousState.receiptCheck2 !== "internationalReceipt"
    );
  };

  // Display a modal indicating the inputted total items value
  // exceeds the total package value
  handleValueModal = () => {
    if (this.doesValueExceedMax()) {
      this.setState({
        displayValueModal: true
      });
    }
  };

  doesValueExceedMax = () => {
    return (
      this.exceedsMaximumValue > 0 &&
      !isDomesticDestination(this.previousState.flowType)
    );
  };

  // Display a modal indicating the inputted total items weight
  // exceeds the total package weight
  handleWeightModal = () => {
    if (
      this.exceedsMaximumWeight > 0 &&
      this.previousState.receiptCheck2 !== "domesticReceipt" &&
      this.previousState.receiptCheck2 !== "internationalReceipt"
    ) {
      this.setState({
        displayWeightModal: true
      });
    }
  };

  // Handle the user's selection in modals
  handleModalChoice = (event) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  // Handle the continue button in the exceeded value modal
  handleValueModalContinue = (event) => {
    event.preventDefault();
    if (this.state.modalValueChoice === "original") {
      // Close the modal and (if the entered value exceeds the allowed maximum)
      // update the value entered so that the total items value equals the
      // total package value
      const amountExceeded =
        this.totalItemsValue - this.packageInfo.totalPackageValue;
      if (amountExceeded > 0) {
        this.setState((state) => {
          const newUnitValue = Math.floor(
            state.itemsList[state.currentIndex].unitValue - amountExceeded
          );
          let newItemsList = [...state.itemsList];
          newItemsList[state.currentIndex] = {
            ...newItemsList[state.currentIndex],
            unitValue: newUnitValue.toString()
          };
          return {
            itemsList: newItemsList,
            displayValueModal: false
          };
        });
      } else {
        this.setState({
          displayValueModal: false
        });
      }
    } else {
      let dataToSend = {
        pkgValueAmt: this.totalItemsValue,
        strCnsCartId: this.cartInfo.encodedCartId
      };
      axios
        .post(
          "/CFOApplication/saveNewPackageValue",
          JSON.stringify(dataToSend),
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          this.previousState.packageInfo.totalPackageValue =
            this.totalItemsValue;
          delete this.previousState.serviceOptions;
          this.updatePreviousState();
          this.setState({ displayValueModal: false });
          this.previousState.renderServiceOption = 1
          this.previousState.stepNumber = this.previousState.stepNumber - 6
          this.props.stepNumberCallback(this.previousState.stepNumber)
          this.props.history.push("/service-options", {
            previousState: this.previousState
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleLoadingBar(false);
        });
    }
  };

  // Handle the continue button in the exceeded weight modal
  handleWeightModalContinue = (event) => {
    event.preventDefault();

    if (this.state.modalWeightChoice === "original") {
      // Close the modal and update the weight entered so that
      // the total items weight equals the total package weight
      this.setState((state) => {
        const amountOuncesExceeded =
          Number(this.totalItemsWeightPounds) * 16 +
          Number(this.totalItemsWeightOunces) -
          (Number(this.packageInfo.weightPounds) * 16 +
            Number(this.packageInfo.weightOunces));
        const currentlyEnteredWeightOunces =
          Number(state.itemsList[state.currentIndex].weightPounds) * 16 +
          Number(state.itemsList[state.currentIndex].weightOunces);
        const newWeightOunces = Math.floor(
          currentlyEnteredWeightOunces - amountOuncesExceeded
        );
        let newItemsList = [...state.itemsList];
        newItemsList[state.currentIndex] = {
          ...newItemsList[state.currentIndex],
          weightPounds: Math.floor(newWeightOunces / 16).toString(),
          weightOunces: (newWeightOunces % 16).toString()
        };
        return {
          itemsList: newItemsList,
          displayWeightModal: false
        };
      });
    } else {
      let dataToSend = {
        weightOzQty:
          Number(this.totalItemsWeightPounds) * 16 +
          Number(this.totalItemsWeightOunces),
        strCnsCartId: this.cartInfo.encodedCartId
      };
      axios
        .post(
          "/CFOApplication/saveNewPackageWeight",
          JSON.stringify(dataToSend),
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          this.previousState.packageInfo.weightPounds =
            this.totalItemsWeightPounds;
          this.previousState.packageInfo.weightOunces =
            this.totalItemsWeightOunces;
          delete this.previousState.serviceOptions;
          this.updatePreviousState();
          this.setState({ displayWeightModal: false });
          this.previousState.renderServiceOption = 1;
          this.previousState.stepNumber = this.previousState.stepNumber - 6
          this.props.stepNumberCallback(this.previousState.stepNumber)
          this.props.history.push("/service-options", {
            previousState: this.previousState
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleLoadingBar(false);
        });
    }
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.itemStatus = 0;
    this.updatePreviousState();
    this.props.history.push("/commercial-sender", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  getFlowTypeOrReceipt = () => {
    return this.previousState.receiptCheck2
      ? this.previousState.receiptCheck2
      : this.previousState.flowType;
  };

  // Returns true if the row is new/unedited
  // (i.e. has the original, default /empty values)
  isNewUneditedRow = (item) => {
    return (
      !item.itemDesc &&
      !item.itemQty &&
      !item.unitValue &&
      (!item.weightPounds || item.weightPounds === "0") &&
      (!item.weightOunces || item.weightOunces === "0")
    );
  };

  displayContinueButton = () => {
    const itemsList = this.state.itemsList;
    const lastIndex = itemsList.length - 1;
    // If there are at least two rows and the user is editing any
    // item except for the last (bottom) row, do not display the continue button
    for (let i = 0; i < lastIndex; i++) {
      if (!itemsList[i].readOnly) {
        return false;
      }
    }
    // If the last/only item is read only, then this row has
    // already been validated
    if (itemsList[lastIndex].readOnly) {
      return true;
    }
    // If there is more than one row in total and the last row is
    // new/unedited, assume the user is not adding this item
    if (itemsList.length > 1 && this.isNewUneditedRow(itemsList[lastIndex])) {
      return true;
    }
    if (this.officialBusiness === "1"){
      return true;
    }
    // Validate the HS tariff field of the last/only item
    if (
      this.state.isHsTariffRequired &&
      (!itemsList[lastIndex].canOmitHsTariff || this.state.hsTariffMandatory) &&
      !itemsList[lastIndex].hsTariffNbr
    ) {
      return false;
    } else if (!this.state.isHsTariffRequired) {
      return true;
    } else if (itemsList[lastIndex].hsTariffNbr === "N/A" && Number(itemsList[lastIndex].itemQty) > 0){
      return true;
    }
    else if (
      itemsList[lastIndex].hsTariffNbr &&
      (itemsList[lastIndex].hsTariffNbr.length < 6 ||
        itemsList[lastIndex].hsTariffNbr.length > 16)
    ) {
      return false;
    }
    // Validate the last or only row depending on the flow type
    const flowTypeOrReceipt = this.getFlowTypeOrReceipt();
    switch (flowTypeOrReceipt) {
      // Domestic flows
      case "military":
      case "territories":
      case "usa":
      case "militaryToUSA":
        // If the description, quantity, and weight have all been
        // provided, and the maximum weight has not been
        // exceeded, then this row is validated
        return (
          itemsList[lastIndex].itemDesc &&
          itemsList[lastIndex].itemDesc.trim() &&
          Number(itemsList[lastIndex].itemQty) > 0 &&
          (Number(itemsList[lastIndex].weightPounds) > 0 ||
            Number(itemsList[lastIndex].weightOunces) > 0) &&
          !(this.exceedsMaximumWeight > 0)
        );
      // International flows
      case "Canada":
      case "other":
        // If the description, quantity, value, and weight have all been
        // provided, and the maximum value and maximum weight have not been
        // exceeded, then this row is validated
        return (
          itemsList[lastIndex].itemDesc &&
          itemsList[lastIndex].itemDesc.trim() &&
          Number(itemsList[lastIndex].itemQty) > 0 &&
          Number(itemsList[lastIndex].unitValue) > 0 &&
          (Number(itemsList[lastIndex].weightPounds) > 0 ||
            Number(itemsList[lastIndex].weightOunces) > 0) &&
          !(this.exceedsMaximumValue > 0) &&
          !(this.exceedsMaximumWeight > 0)
        );
      // Receipt flows
      case "domesticReceipt":
        return (
          // If the description and quantity have been
          // provided, then this row is validated
          itemsList[lastIndex].itemDesc &&
          itemsList[lastIndex].itemDesc.trim() &&
          Number(itemsList[lastIndex].itemQty) > 0
        );
      case "internationalReceipt":
        return (
          // If the description, quantity, and value have all been
          // provided, and the maximum value has not been
          // exceeded, then this row is validated
          itemsList[lastIndex].itemDesc &&
          itemsList[lastIndex].itemDesc.trim() &&
          Number(itemsList[lastIndex].itemQty) > 0 &&
          Number(itemsList[lastIndex].unitValue) > 0 &&
          !(this.exceedsMaximumValue > 0)
        );
      default:
        return false;
    }
  };

  // Verify whether the last row contains an item that is
  // prohibited or restricted before proceeding to the next page
  checkLastRowProhibitions = (e) => {
    e.preventDefault();
    // Before checking if the last row contains a prohibited/restricted item,
    // verify that the total items value matches what was entered earlier in the
    // flow (if provided at all, as determined by the flow)
    const flowTypeOrReceipt = this.getFlowTypeOrReceipt();
    const totalItemsValue = this.totalItemsValue || 0;
    const totalPackageValue = this.packageInfo.totalPackageValue || 0;
    switch (flowTypeOrReceipt) {
      case "Canada":
      case "other":
      case "internationalReceipt":
        if (Number(totalItemsValue) !== Number(totalPackageValue)) {
          this.setState({
            displayValueModal: true
          });
          return;
        }
        break;
      default:
        break;
    }
    // Determine if the last row needs to be checked for prohibited/restricted
    // items
    if (
      !this.isNewUneditedRow(
        this.state.itemsList[this.state.itemsList.length - 1]
      )
    ) {
      this.checkForProhibitions(this.state.itemsList.length, true);
    } else {
      this.handleContinueButton();
    }
  };

  handleContinueButton = () => {
    this.previousState.itemStatus = 1;
    this.updatePreviousState();
    let dataToSend = {
      labelItems: this.previousState.itemEntry.itemsList,
      strCnsCartId:
        this.props.location.state.previousState.cartInfo.encodedCartId,
      cnsCartId: 0
    };
    this.props.toggleLoadingBar(true);
    axios
      .post("/CFOApplication/saveLabelItems", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        this.props.history.push("/aes-exemptions", {
          previousState: this.previousState
        });
        dataLayer.element = "Continue";
        analytics.setDataLayerInfo(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  updatePreviousState = () => {
    let newItemsList = [...this.state.itemsList];
    const lastIndex = newItemsList.length - 1;
    if (this.isNewUneditedRow(newItemsList[lastIndex])) {
      newItemsList.splice(lastIndex, 1);
    }
    for (let i in newItemsList) {
      newItemsList[i] = {
        ...newItemsList[i],
        countryId: !newItemsList[i].countryOfOrigin
          ? 0
          : newItemsList[i].countryOfOrigin.trim()
            ? this.getCountryId(newItemsList[i].countryOfOrigin)
            : 0,
        weightPounds: newItemsList[i].weightPounds || "0",
        weightOunces: newItemsList[i].weightOunces || "0",
        itemDesc: newItemsList[i].itemDesc.replaceAll(/[^a-zA-Z0-9.,\- ]/g, "") // Sanitize for WebTools
        // itemDesc: newItemsList[i].itemDesc.replaceAll(/#|&|'/g, "") 
      };
    }
    this.previousState.itemEntry = {
      globalKey: this.state.globalKey,
      itemsList: newItemsList,
      landedCostInputs: this.showLandedCostSection()
        ? this.state.landedCostInputs || null
        : null,
      tlcAmounts: this.showLandedCostSection()
        ? this.state.tlcAmounts || null
        : null
    };
  };

  getCountryId = (countryName) => {
    const countryList = this.props.countryList;
    for (let i in countryList) {
      if (
        countryList[i].countryName.toUpperCase() ===
        countryName.trim().toUpperCase()
      ) {
        return countryList[i].countryDbId;
      }
    }
    return 0;
  };

  handleHsTariffCodeModal = (event, value) => {
    if (event) {
      event.preventDefault();
    }
    this.handleValueModal();
    this.handleWeightModal();
    this.setState({
      lookupHsTariffCodeModalToDisplay: value
    });
  };

  // Update the HS Tariff # at the specified index with the specified value
  updateHsTariff = (index, value) => {
    index--;
    this.setState((state) => {
      let newItemsList = [...state.itemsList];
      newItemsList[index] = {
        ...newItemsList[index],
        hsTariffNbr: value
      };
      return {
        itemsList: newItemsList
      };
    });
  };

  // Flag that determines whether an HS tariff that is initially required
  // can be omitted for a specific item
  setCanOmitHsTariff = (value, index) => {
    index--;
    this.setState((state) => {
      let newItemsList = [...state.itemsList];
      newItemsList[index] = {
        ...newItemsList[index],
        canOmitHsTariff: value
      };
      return {
        itemsList: newItemsList
      };
    });
  };

  handleProhibitedItemModal = (value, proceedIfAllowed, event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      prohibitedRestrictedModalToDisplay: value,
      proceedIfAllowed: proceedIfAllowed
    });
  };

  showLandedCostSection = () => {
    return (
      this.state.isHsTariffRequired &&
      !isDomesticDestination(this.previousState.flowType)
    );
  };

  handleLandedCostEstimateModal = (event, value) => {
    event.preventDefault();
    this.setState({
      displayLandedCostEstimateModal: value
    });
  };

  toggleCallToLandedCostApi = (value) => {
    this.setState({
      callLandedCostApi: value
    });
  };

  setLandedCostInputs = (landedCostInputs) => {
    this.setState({
      landedCostInputs: landedCostInputs
    });
  };

  setTlcAmounts = (amounts) => {
    if (amounts) {
      const duties = !isNaN(amounts.duties)
        ? amounts.duties.toFixed(2)
        : "0.00";
      const fees = !isNaN(amounts.fees) ? amounts.fees.toFixed(2) : "0.00";
      const taxes = !isNaN(amounts.taxes) ? amounts.taxes.toFixed(2) : "0.00";
      this.setState({
        tlcAmounts: {
          duties: duties,
          fees: fees,
          taxes: taxes,
          sum: (Number(duties) + Number(fees) + Number(taxes)).toFixed(2)
        }
      });
    } else {
      this.setState({
        tlcAmounts: null
      });
    }
  };

  handleTlcEmail = (event) => {
    this.setState({
      tlcEmail: event.currentTarget.value
    });
  };

  renderItems = () => {
    return this.state.itemsList.map(this.renderItemRow);
  };

  backToProhibitions = (e) => {
    e.preventDefault();
    this.props.history.push("/prohibitions?itemEntry", {
      previousState: this.previousState
    });
  };

  renderItemRow = (item, index) => {
    index++;

    return (
      <div
        key={item.key}
        className={
          "row items-row items-row-item" + (item.readOnly ? " row-locked" : "")
        }
      >
        <div className="col-1 item-cell ic-item-number">
          <span className="ic-item-number-id">{index}</span>
        </div>
        <div className="col-11 ic-right-col">
          <div className="row flex-xl-column-reverse">
            <div className="col-12 bottom-row-placeholder-container">
              {(this.state.displayCommercial && 
                (this.previousState.flowType !== "usa") &&
                (this.previousState.flowType === "other" ||
                (this.previousState.flowType === "military" && this.officialBusiness === "0"))) 
                //(this.officialBusiness && this.officialBusiness === "0" && this.previousState.flowType !== "usa") 
                ? (
                <div className="row items-row-inner items-row-inner-commercial">
                  <div className="col-6 col-xl-3 item-cell ic-item-tariff">
                    <div className="form-group-alt">
                      <div className="inline-label-wrapper">
                        <label>
                          {(this.state.isHsTariffRequired ? "* " : "") +
                            "HS Tariff #"}
                        </label>
                      </div>
                      {this.displayHsTariff(
                        item.readOnly,
                        item.hsTariffNbr
                      ) && (
                          <input
                            name="hsTariffNbr"
                            className="form-control"
                            type="text"
                            readOnly={item.readOnly}
                            maxLength={this.maxLengths.hsTariffNbr}
                            value={item.hsTariffNbr}
                            placeholder="0000.000.0000"
                            onChange={(event) => {
                              this.handleItemEntryEvent(event, index);
                            }}
                          />
                        )}
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 item-cell ic-item-origin-country">
                    <div className="form-group-alt">
                      <div className="inline-label-wrapper">
                        <label>Country of Origin</label>
                      </div>
                      {this.displayCountryOfOrigin(
                        item.readOnly,
                        item.countryOfOrigin
                      ) && (
                          <Autocomplete
                            userInput={item.countryOfOrigin}
                            suggestionList={this.formattedCountryList}
                            parentCallback={(countryName) => {
                              this.getDataFromChild(countryName, index);
                            }}
                            type="text"
                            name="countryOfOrigin"
                            className="form-control country-select"
                            id="countryOfOrigin"
                            readOnly={item.readOnly}
                            hidePlaceholder={true}
                            source="itemEntry"
                          />
                        )}
                    </div>
                  </div>
                  {this.state.isHsTariffRequired && !item.readOnly && (
                    <React.Fragment>
                      <div className="col-12 col-xl-6 submit-hs-tariff-code-wrapper">
                        <button
                          className="btn btn-primary"
                          id="submit-hs-tariff-code-button"
                          type="submit"
                          onClick={(e) =>
                            this.handleHsTariffCodeModal(e, index)
                          }
                        >
                          <strong id="submit-hs-tariff-code-text">
                            Lookup HS Tariff Code
                          </strong>
                        </button>
                      </div>
                      <LookupHsTariffCode
                        isOpen={
                          this.state.lookupHsTariffCodeModalToDisplay === index
                        }
                        toggle={this.handleHsTariffCodeModal}
                        itemIndex={index}
                        item={item}
                        maxLengths={this.maxLengths}
                        handleItemEntry={this.handleItemEntry}
                        updateHsTariff={this.updateHsTariff}
                        hsTariffMandatory={this.state.hsTariffMandatory}
                        setCanOmitHsTariff={this.setCanOmitHsTariff}
                        shipToCountryCode={this.state.shipToCountryCode}
                        handleValueModal={this.handleValueModal}
                        handleWeightModal={this.handleWeightModal}
                        toggleLoadingBar={this.props.toggleLoadingBar}
                        countryList={this.formattedCountryList}
                        lookupCodes={this.props.lookupCodes}
                        flowType={this.previousState.flowType}
                        flowTypeOrReceipt={this.getFlowTypeOrReceipt()}
                        checkForProhibitions={this.checkForProhibitions}
                      />
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <div className="bottom-row-placeholder"></div>
              )}
            </div>
            <div className="col-12">
              <div className="row items-row-inner items-row-inner-top">
                <div className="col-sm-4 col-xl-4 item-cell ic-item-description">
                    {this.officialBusiness === "1" ? (
                      <select
                        id="itemDesc"
                        className="form-control has-dependent"
                        name="itemDesc"
                        disabled={item.readOnly}
                        readOnly={item.readOnly}
                        required="required"
                        value={item.hsTariffNbr}
                        onChange={(event) => {
                          this.handleOfficialBusinessTariffCode(event, index);
                        }}
                      >
                        <option
                          className="eelOption"
                          value=""
                          data-dependent="0"
                        >
                          Select Item
                        </option>

                        <option
                          className="eelOption"
                          value="8543.70.9665"
                          data-dependent="0"
                        >
                          Military/Defense Agency Electronics Equipment (Lithium Meets Air Transportation Requirements) - (Lithium and Lithium-ion Cells and Batteries - General 622.5 - 622.53 - Exhibit 622.5)
                        </option>
                        <option
                          className="eelOption"
                          value=" "
                          data-dependent="0"
                        >
                          Military/Defense Agency Urinalysis
                        </option>
                        <option
                          className="eelOption"
                          value="8523.80.2000"
                          data-dependent="0"
                        >
                          Military/Defense Agency Evidence
                        </option>
                        <option
                          className="eelOption"
                          value="8487.90.0080"
                          data-dependent="0"
                        >
                          Military/Defense Agency Equipment Parts (Without Ammunition or Lithium)
                        </option>
                        <option
                          className="eelOption"
                          value="4911.99.0000"
                          data-dependent="0"
                        >
                          Military/Defense Agency Documents
                        </option>
                      </select>
                    ) : (
                        <input
                            className="form-control input-desc"
                            type="text"
                            name="itemDesc"
                            required="required"
                            readOnly={item.readOnly}
                            maxLength={this.maxLengths.itemDesc}
                            value={item.itemDesc}
                            onChange={(event) => {
                              this.handleItemEntryEvent(event, index);
                            }}
                        /> 
                    )}
                  
                  
                  <label
                    id={"item[description][" + index + "][]-error"}
                    className="error"
                    htmlFor="itemDesc"
                  ></label>
                </div>
                <div className="col-6 col-sm-4 col-xl-1 item-cell ic-xs-full ic-item-quantity">
                  <input
                    className="form-control numbersonly input-number input-qty"
                    type="text"
                    name="itemQty"
                    required="required"
                    maxLength={this.maxLengths.itemQty}
                    readOnly={item.readOnly}
                    value={item.itemQty}
                    onChange={(event) => {
                      this.handleItemEntryEvent(event, index);
                    }}
                  />
                </div>
                <div className="col-6 col-sm-4 col-xl-2 item-cell ic-xs-full ic-item-value">
                  <div className="field-wrapper with-prefix prefix-is-1-chars">
                    <span className="field-icon field-prefix">$</span>
                    <input
                      name="unitValue"
                      className="form-control numbersonly input-number input-value text-right customized"
                      type="text"
                      required="required"
                      maxLength={this.maxLengths.unitValue}
                      data-field-prefix="$"
                      readOnly={item.readOnly}
                      value={item.unitValue}
                      onChange={(event) => {
                        this.handleItemEntryEvent(event, index);
                      }}
                      onBlur={this.handleValueModal}
                    />
                  </div>
                </div>
                <div className="col-xl-3 item-cell ic-xs-full ic-item-weight">
                  <div className="row weight-row">
                    <div className="col-6 weight-col weight-col-pounds">
                      {this.displayWeight(item.readOnly, item.weightPounds) ? (
                        <div className="field-wrapper with-suffix suffix-is-3-chars">
                          <input
                            name="weightPounds"
                            className="form-control numbersonly input-number input-weight-pounds customized"
                            type="text"
                            maxLength={this.maxLengths.weightPounds}
                            data-field-suffix="lbs"
                            readOnly={item.readOnly}
                            value={item.weightPounds}
                            placeholder="0"
                            onChange={(event) => {
                              this.handleItemEntryEvent(event, index);
                            }}
                            onBlur={this.handleWeightModal}
                          />
                          <span className="field-icon field-suffix">lbs</span>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-6 weight-col weight-col-ounces">
                      {this.displayWeight(item.readOnly, item.weightOunces) ? (
                        <div className="field-wrapper with-suffix suffix-is-2-chars">
                          <input
                            name="weightOunces"
                            className="form-control numbersonly input-number input-weight-ounces customized"
                            type="text"
                            maxLength={this.maxLengths.weightOunces}
                            data-field-suffix="oz"
                            readOnly={item.readOnly}
                            value={item.weightOunces}
                            placeholder="0"
                            onChange={(event) => {
                              this.handleItemEntryEvent(event, index);
                            }}
                            onBlur={this.handleWeightModal}
                          />
                          <span className="field-icon field-suffix">oz</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 item-cell ic-item-actions">
                  <ul>
                    {this.displayAddButton(item, index) ? (
                      <li className="btn-add-container">
                        <button
                          type="button"
                          className="btn btn-chromeless btn-add-item"
                          onClick={() => {
                            this.checkForProhibitions(index);
                          }}
                        >
                          Add
                        </button>
                      </li>
                    ) : null}
                    <ProhibitedRestrictedItem
                      isOpen={
                        this.state.prohibitedRestrictedModalToDisplay === index
                      }
                      toggle={this.handleProhibitedItemModal}
                      prohibitionsRestrictions={
                        this.state.prohibitionsRestrictions
                      }
                      handleAddButton={this.handleAddButton}
                      handleContinueButton={this.handleContinueButton}
                      proceedIfAllowed={this.state.proceedIfAllowed}
                      itemIndex={index}
                      lookupCodes={this.props.lookupCodes}
                    />
                    {this.displayEditButton(item.readOnly) ? (
                      <li className="btn-edit-container">
                        <button
                          type="button"
                          className="btn btn-chromeless btn-edit-item"
                          onClick={() => {
                            this.handleEditButton(index);
                          }}
                        >
                          <span>Edit</span>
                        </button>
                      </li>
                    ) : null}
                    {this.displayRemoveButton() ? (
                      <li className="btn-remove-container">
                        <button
                          type="button"
                          className="btn btn-chromeless btn-remove-item"
                          onClick={() => {
                            this.handleRemoveButton(index);
                          }}
                        >
                          <span>Remove</span>
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderValueExceededModal = () => {
    return (
      <Modal isOpen={this.state.displayValueModal}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h2 className="modal-title text-center d-block text-center">
              {this.doesValueExceedMax()
                ? "Total Package Value Exceeded"
                : "Total Package Value Inconsistent"}
            </h2>
          </div>
          <div className="modal-body">
            <form
              className="customs-form"
              id="form-value-change-warning"
              action="#"
              method="post"
            >
              <div className="container">
                <div className="row section-row">
                  <div className="col-12">
                    <p>
                      {this.doesValueExceedMax()
                        ? "The combined value of your package items has exceeded the Total Package Value as previously entered."
                        : "The combined value of the items does not match your total package value as previously entered."}
                    </p>
                    <p className="text-center">
                      Choose an option for Total Package Value below and click
                      Continue.
                    </p>
                  </div>
                </div>

                <div className="row section-row text-center">
                  <div className="wem-left offset-lg-1 col-lg-5">
                    <p>Value previously entered:</p>
                    <p>
                      <label>
                        <input
                          name="modalValueChoice"
                          type="radio"
                          value="original"
                          checked={this.state.modalValueChoice === "original"}
                          onChange={this.handleModalChoice}
                        />{" "}
                        <span id="span-value-exceeded-modal-reported-value">
                          {"$" + this.packageInfo.totalPackageValue}
                        </span>
                      </label>
                    </p>
                    <p className="text-left">
                      Keep this amount to match total package value previously
                      entered.
                    </p>
                  </div>

                  <div className="wem-right col-lg-5 mt-3 pt-3 mt-lg-0 pt-lg-0">
                    <p>Current Value:</p>
                    <p>
                      <label>
                        <input
                          id="value-exceeded-modal-new-value-value"
                          name="modalValueChoice"
                          type="radio"
                          value="change"
                          checked={this.state.modalValueChoice === "change"}
                          onChange={this.handleModalChoice}
                        />{" "}
                        <span id="span-value-exceeded-modal-value">
                          {"$" + this.totalItemsValue}
                        </span>
                      </label>
                    </p>
                    <p className="text-left">
                      Select this amount and update total package value.
                    </p>
                  </div>
                </div>

                <div className="row section-row">
                  <div className="col-12">
                    <p>
                      NOTE: If you choose to update the total package value
                      based on the current amount, you may be prompted to review
                      and select a new shipping option.
                    </p>
                  </div>
                </div>

                <div className="row section-row">
                  <div className="col-12">
                    <div className="buttons-group centered">
                      <ul>
                        <li>
                          <button
                            className="btn btn-primary btn-continue"
                            type="submit"
                            onClick={this.handleValueModalContinue}
                          >
                            Continue
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  renderWeightExceededModal = () => {
    return (
      <Modal isOpen={this.state.displayWeightModal}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <h2 className="modal-title text-center d-block text-center">
              Total Package Weight Exceeded
            </h2>
          </div>
          <div className="modal-body">
            <form
              className="customs-form"
              id="form-weight-change-warning"
              action="#"
              method="post"
            >
              <div className="container">
                <div className="row section-row">
                  <div className="col-12">
                    <p>
                      The combined weight of your package items has exceeded the
                      Total Package Weight as previously entered.
                    </p>
                    <p>
                      Choose an option for Total Package Weight below and click
                      Continue.
                    </p>
                  </div>
                </div>
                <div className="row section-row text-center">
                  <div className="wem-left offset-lg-1 col-lg-5">
                    <p>Weight previously entered:</p>
                    <p>
                      <label>
                        <input
                          name="modalWeightChoice"
                          type="radio"
                          value="original"
                          checked={this.state.modalWeightChoice === "original"}
                          onChange={this.handleModalChoice}
                        />{" "}
                        <span id="span-weight-exceeded-modal-reported-pounds">
                          {this.packageInfo.weightPounds}
                        </span>{" "}
                        lbs.{" "}
                        <span id="span-weight-exceeded-modal-reported-ounces">
                          {this.packageInfo.weightOunces}
                        </span>{" "}
                        oz.
                      </label>
                    </p>
                    <p className="text-left">
                      Keep this amount with no additional changes in shipping or
                      pricing.
                    </p>
                  </div>
                  <div className="wem-right col-lg-5 mt-3 pt-3 mt-lg-0 pt-lg-0">
                    <p>Current Weight:</p>
                    <p>
                      <label>
                        <input
                          id="weight-exceeded-modal-new-weight-value"
                          name="modalWeightChoice"
                          type="radio"
                          value="change"
                          checked={this.state.modalWeightChoice === "change"}
                          onChange={this.handleModalChoice}
                        />{" "}
                        <span id="span-weight-exceeded-modal-pounds">
                          {this.totalItemsWeightPounds}
                        </span>{" "}
                        lbs.{" "}
                        <span id="span-weight-exceeded-modal-ounces">
                          {this.totalItemsWeightOunces}
                        </span>{" "}
                        oz.
                      </label>
                    </p>
                    <p className="text-left">
                      Select this amount and choose updated shipping and
                      pricing.
                    </p>
                  </div>
                </div>
                <div className="row section-row">
                  <div className="col-12">
                    <div className="buttons-group centered">
                      <ul>
                        <li>
                          <button
                            className="btn btn-primary btn-continue"
                            type="submit"
                            onClick={this.handleWeightModalContinue}
                          >
                            Continue
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    this.updateValueAndWeight();

    return (
      <React.Fragment>
        {this.state.hasLoaded ? (
          <React.Fragment>
            <div id="cfo-wrapper">
              <div id="inner">
                <main className="section" id="customs-form-2019-main">
                  <section className="section" id="app-form">
                    <div className="container">
                      <form
                        className="customs-form"
                        id="customs-form"
                        action="#"
                        method="post"
                      >
                        <div className="row section-row align-items-center">
                          <div className="col-lg-4">
                            <h1>Customs Form</h1>
                            <p className="note">* indicates a required field</p>
                          </div>
                          <div className="col-lg-8">
                            {this.props.loggedIn ? (
                              <LoggedInNav />
                            ) : (
                              <ul className="title-links">
                                <li>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                                  >
                                    FAQs{" "}
                                    <span>
                                      <img src={caretRight} alt="open link" />
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>

                        <div className="row section-row">
                          <div className="col-9">
                            <h2>Add items to your package.</h2>
                            {((this.state.isHsTariffRequired && 
                             ((this.previousState.flowType === "usa" ||
                             this.previousState.flowType === "military") && this.officialBusiness === "0")) ||
                             this.previousState.flowType === "other" ) 
                             && (
                              <p>
                                Shipments to the EU will require an HS Tariff
                                Code based on item description. If you do not
                                have one, you will be able to look it up as you
                                add items to your shipment.
                              </p>
                            )}
                            <p>
                              Must add at least one item with a maximum of 30.
                            </p>
                            {this.previousState.receiptCheck2 ? (
                              <p>
                                Please note, entering the item weight is
                                optional. If item weight is entered for any
                                item, the sum of all item weight entered would
                                be validated against the total package weight,
                                and must be less than or equal to that amount in
                                pounds and ounces.
                              </p>
                            ) : (
                              <p>
                                Please note, the sum of all item weight entered
                                will be validated against the total package
                                weight, and must be less than or equal to that
                                amount in pounds and ounces. Items with
                                different values must be entered on different
                                lines.
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row section-row items-table">
                          <div className="col-12">
                            <div className="row items-row items-row-header align-items-center">
                              <div className="col-xl-1 item-cell ic-item-number">
                                Item #
                              </div>
                              <div className="col-xl-11 ic-right-col">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row items-row-inner items-row-inner-top">
                                      <div className="col-sm-4 col-xl-4 item-cell ic-item-description">
                                        * Description
                                      </div>
                                      <div className="col-sm-4 col-xl-1 item-cell ic-item-quantity">
                                        * Total Qty
                                      </div>
                                      <div className="col-sm-4 col-xl-2 item-cell ic-item-value">
                                        {this.previousState.flowType ===
                                          "Canada" ||
                                          this.previousState.flowType === "other"
                                          ? "* "
                                          : ""}
                                        Total Item Value
                                      </div>
                                      <div className="col-xl-3 item-cell ic-item-weight">
                                        {!this.previousState.receiptCheck2
                                          ? "* "
                                          : ""}
                                        Weight
                                      </div>
                                      <div className="col-xl-2 item-cell ic-item-actions">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div
                                  id="items-rows"
                                  className={
                                    this.displayRemoveButton()
                                      ? "show-all-remove-buttons"
                                      : null
                                  }
                                >
                                  {this.renderItems()}
                                </div>
                              </div>
                            </div>
                            <div className="row items-row items-subtotal-row">
                              <div className="col-xl-1 d-none d-xl-flex">
                                &nbsp;
                              </div>
                              <div className="col-xl-11">
                                <div className="row">
                                  <div className="col-6 col-xl-3 offset-xl-4 item-cell ic-item-total-value">
                                    <div className="col-12 text-right">
                                      <span className="ic-label">
                                        Total Items Value{" "}
                                      </span>
                                      <div className="more-info">
                                        <span
                                          className="more-info-link modal-trigger"
                                          data-target="#modal-total-value"
                                          data-toggle="modal"
                                        >
                                          <TotalItemValuePopover targetName="returnITotalItemValuePopover" />
                                        </span>
                                        <div
                                          className="more-info-content modal fade"
                                          id="modal-total-value"
                                        >
                                          <div
                                            className="modal-dialog modal-lg"
                                            role="document"
                                          >
                                            <div className="modal-content">
                                              <div className="modal-body">
                                                <p>
                                                  Total value of all of the
                                                  items you have added here.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ic-data">
                                        $
                                        <span
                                          className="js-value"
                                          id="total-value-reported"
                                        >
                                          {this.totalItemsValue}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 col-xl-3 item-cell ic-item-total-weight">
                                    <div id="current-weight-output">
                                      <span className="ic-label">
                                        Total Items Weight{" "}
                                        <span className="items-weight-entered-text">
                                          Entered
                                        </span>
                                      </span>
                                      <div className="more-info">
                                        <span
                                          className="more-info-link modal-trigger"
                                          data-target="#modal-total-weight"
                                          data-toggle="modal"
                                        >
                                          <TotalItemWeightPopover targetName="returnTotalItemWeightPopover" />
                                        </span>
                                        <div
                                          className="more-info-content modal fade"
                                          id="modal-total-weight"
                                        >
                                          <div
                                            className="modal-dialog modal-lg"
                                            role="document"
                                          >
                                            <div className="modal-content">
                                              <div className="modal-body">
                                                <p>
                                                  Total weight of all of the
                                                  items you have added here.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ic-data">
                                        {this.displayTotalItemsWeight() ? (
                                          <span className="items-weight-value">
                                            <span
                                              className="js-value"
                                              id="total-weight-pounds-reported"
                                            >
                                              {this.totalItemsWeightPounds}
                                            </span>{" "}
                                            lbs.{" "}
                                            <span
                                              className="js-value"
                                              id="total-weight-ounces-reported"
                                            >
                                              {this.totalItemsWeightOunces}
                                            </span>{" "}
                                            oz.
                                          </span>
                                        ) : (
                                          <span className="items-weight-error">
                                            No item weight entered.
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!isDomesticDestination(
                              this.previousState.flowType
                            ) || !this.previousState.receiptCheck2 ? (
                              <div className="row items-row items-subtotal-row items-final-row">
                                <div className="col-xl-1 d-none d-xl-flex">
                                  &nbsp;
                                </div>
                                <div className="col-xl-11">
                                  <div className="row">
                                    <div className="col-6 col-xl-3 offset-xl-4 item-cell ic-item-total-value item-cell ic-item-total-value">
                                      {!isDomesticDestination(
                                        this.previousState.flowType
                                      ) ? (
                                        <div className="col-12 text-right">
                                          <div className="ic-label">
                                            Total Package Value
                                          </div>
                                          <div className="ic-data">
                                            $
                                            <span id="total-package-value">
                                              {this.packageInfo
                                                .totalPackageValue || 0}
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>
                                    <div className="col-6 col-xl-3 item-cell ic-item-total-weight">
                                      {!this.previousState.receiptCheck2 ? (
                                        <React.Fragment>
                                          <div className="ic-label">
                                            Total Package Weight
                                          </div>
                                          <div className="ic-data">
                                            <span id="total-package-weight-pounds">
                                              {this.packageInfo.weightPounds ||
                                                0}
                                            </span>{" "}
                                            lbs.{" "}
                                            <span id="total-package-weight-ounces">
                                              {this.packageInfo.weightOunces ||
                                                0}
                                            </span>{" "}
                                            oz.
                                          </div>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <React.Fragment />
                            )}
                            {this.displayMaximumWeight() &&
                              !this.displayMaximumValue() ? (
                              <div
                                className="row items-row items-warning-row"
                                id="items-warning-row-weight"
                              >
                                <div className="col-12">
                                  <p>
                                    <img alt="!" src={alert} /> You have met the
                                    maximum for Total Package Weight. If you
                                    would like to add another item, you must
                                    edit or remove an item.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {this.displayMaximumValue() &&
                              !this.displayMaximumWeight() ? (
                              <div
                                className="row items-row items-warning-row"
                                id="items-warning-row-value"
                              >
                                <div className="col-12">
                                  <p>
                                    <img alt="!" src={alert} /> You have met the
                                    maximum for Total Package Value. If you
                                    would like to add another item, you must
                                    edit or remove an item.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {this.displayMaximumWeight() &&
                              this.displayMaximumValue() ? (
                              <div
                                className="row items-row items-warning-row"
                                id="items-warning-row-both"
                              >
                                <div className="col-12">
                                  <p>
                                    <img alt="!" src={alert} /> You have met the
                                    maximum for Total Package Value and Total
                                    Package Weight. If you would like to add
                                    another item, you must edit or remove an
                                    item.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            <div className="row items-row items-notes-row">
                              <div className="col-12 item-cell ic-note">
                                <a
                                  href="/prohibitions?itemEntry"
                                  className="set-back-button"
                                  data-back-button="Back to Items Table"
                                  onClick={(e) => this.backToProhibitions(e)}
                                >
                                  <strong>
                                    Prohibitions and Shipping Restrictions
                                  </strong>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row section-row">
                          <div className="col-lg-6">
                            <div className="subsection button-section">
                              <div className="row">
                                <div className="col-12">
                                  <div className="customs-form-buttons">
                                    <div className="form-button-container">
                                      <button
                                        className="previous btn btn-outline-primary"
                                        type="button"
                                        onClick={this.handleBackButton}
                                      >
                                        Back
                                      </button>
                                    </div>
                                    {this.displayContinueButton() ? (
                                      <div className="form-button-container">
                                        <button
                                          className="btn btn-primary"
                                          id="submit"
                                          type="submit"
                                          onClick={(e) =>
                                            this.checkLastRowProhibitions(e)
                                          }
                                        >
                                          Continue
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.showLandedCostSection() && (
                          <div id="tlc-wrapper">
                            <p>
                              <strong id="tlc-text-header">
                                For more information
                              </strong>
                            </p>
                            <p>See estimate of landed cost paid by recipient</p>
                            <div className="tlc-button-wrapper">
                              <button
                                className="previous btn btn-outline-primary"
                                id="view-landed-cost-summary-button"
                                type="submit"
                                disabled={
                                  !this.displayContinueButton() ||
                                  !hsTariffCodeUtils.hasAtLeastOne(
                                    this.state.itemsList
                                  )
                                }
                                onClick={(e) => {
                                  this.handleLandedCostEstimateModal(e, true);
                                  this.toggleCallToLandedCostApi(true);
                                }}
                              >
                                View Landed Cost Summary
                              </button>
                            </div>
                            <LandedCostEstimate
                              isOpen={this.state.displayLandedCostEstimateModal}
                              toggle={this.handleLandedCostEstimateModal}
                              callLandedCostApi={this.state.callLandedCostApi}
                              toggleCallToLandedCostApi={
                                this.toggleCallToLandedCostApi
                              }
                              items={this.state.itemsList}
                              isNewUneditedRow={this.isNewUneditedRow}
                              returnAddressCountryCode={
                                this.state.shipFromCountryCode
                              }
                              deliveryAddress={{
                                ...this.previousState.recipientInfo,
                                countryCode: this.state.shipToCountryCode
                              }}
                              serviceInfo={{
                                ...this.previousState.serviceOptions,
                                productClass: this.state.productClass
                              }}
                              landedCostInputs={this.state.landedCostInputs}
                              setLandedCostInputs={this.setLandedCostInputs}
                              tlcAmounts={this.state.tlcAmounts}
                              setTlcAmounts={this.setTlcAmounts}
                              cartId={this.cartInfo.encodedCartId}
                              tlcEmail={this.state.tlcEmail}
                              handleTlcEmail={this.handleTlcEmail}
                              toggleLoadingBar={this.props.toggleLoadingBar}
                              get2LetterCountryCode={this.get2LetterCountryCode}
                            />
                            <p>
                              International Shipping Prohibitions & Restrictions
                            </p>
                            <div className="tlc-button-wrapper">
                              <a
                                className="previous btn btn-outline-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.usps.com/international/shipping-restrictions.htm"
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </section>
                </main>
              </div>
            </div>

            {this.renderValueExceededModal()}
            {this.renderWeightExceededModal()}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ItemEntry);
